import { Button } from 'src/design-system'
import { Icon, MagnifyingGlass } from '@phosphor-icons/react'
import * as React from 'react'
import cn from 'classnames'
import { Autocomplete } from './autocomplete'
import { useActionKey } from '../../utils/use-action-key'
import { RailsActionForm } from 'components/rails-form-button/rails-form-button'
import { trackEvent } from '../../services/event-tracker'

export type QuickSearchProps = {
  className?: string
  label?: string
  icon?: Icon | null
}

export const QuickSearch = (props: QuickSearchProps) => {
  const { className, label = 'Search', icon: Icon = MagnifyingGlass } = props

  const actionKey = useActionKey()

  const triggerEscAction = () => {
    const event = new KeyboardEvent('keydown', { key: 'Escape' })
    document.dispatchEvent(event)
  }

  const openSearch = React.useCallback((source: string) => {
    triggerEscAction()
    const button: HTMLButtonElement | null = document.querySelector(
      '.aa-DetachedSearchButton'
    )
    trackEvent('$quick-search-opened', { source })
    button?.click()
  }, [])

  React.useEffect(() => {
    // Open the modal on cmd + k or ctrl + k depending on the OS
    const listenForCmdK = (event: KeyboardEvent) => {
      if (event.key === 'k' && (event.metaKey || event.ctrlKey)) {
        event.preventDefault()
        openSearch('keyboard shortcut')
      }
    }

    document.addEventListener('keydown', listenForCmdK)

    return () => {
      document.removeEventListener('keydown', listenForCmdK)
    }
  }, [openSearch])

  return (
    <>
      <Autocomplete />
      <RailsActionForm method="delete" action="/users/logout" formMethod="post">
        <button type="submit" className="hidden hidden-logout-button" />
      </RailsActionForm>
      <Button
        variant="outline"
        colourVariant="paper"
        onClick={() => {
          openSearch('side nav button')
        }}
        className={cn(
          'flex items-center w-full max-w-none bg-white shadow-sm',
          className
        )}
      >
        {Icon && (
          <Icon weight="bold" className="shrink-0 w-3.5 h-3.5 text-gray-400" />
        )}
        {label && (
          <span className="flex-grow text-left opacity-75">{label}</span>
        )}
        {actionKey && (
          <span className="ml-2 text-xs opacity-50">{actionKey[0]}K</span>
        )}
      </Button>
    </>
  )
}
